import React from 'react';

function DisbursementCenterSvg({
	width = '25',
	height = '25',
	stroke = '#919191',
    className,
}){
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 22 22'
            className={className}
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M4.33 19.68h16.34M5.41 12.58v7.1M19.59 12.58v7.1M11.96 13.2H7.48v6.48h4.48V13.2zM17.41 13.2h-3.28v4.24h3.28V13.2zM16.57 8.86c0 1.13.92 2.05 2.05 2.05s2.05-.92 2.05-2.05l-3.29-4.54H7.62L4.33 8.86c0 1.13.92 2.05 2.05 2.05s2.05-.92 2.05-2.05c0 1.13.92 2.05 2.05 2.05s2.05-.92 2.05-2.05c0 1.13.92 2.05 2.05 2.05s2.05-.92 2.05-2.05h-.07.01z'
			/>
		</svg>
	);
}

export default DisbursementCenterSvg;
