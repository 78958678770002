import React from 'react';

function ReportsSvg({
	width = '24',
	height = '24',
	stroke = '#FFC351',
}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
		>
			<path fill={stroke} d='M3 12h4v9H3v-9zm14-4h4v13h-4V8zm-7-6h4v19h-4V2z' />
		</svg>
	);
}

export default ReportsSvg;
