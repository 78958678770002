import React from 'react';

function DCVisitsSvg({
	width = '25',
	height = '24',
	stroke = '#919191',
}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 25 24'
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0.75'
				d='M11.04 6.61h2.92M11.04 7.71h2.92M11.07 6.61c1.04 0 1.89.49 1.89 1.1 0 .57-.75 1.03-1.7 1.09M11.27 8.8l1.62 2.15'
			/>
			<path
				stroke='#919191'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M23.31 14.5c-2.99 0-5.41-2.12-5.41-4.73 0 2.61-2.42 4.73-5.41 4.73s-5.41-2.12-5.41-4.73c0 2.61-2.42 4.73-5.41 4.73M3.17 19.22h18.66M5.28 15.51v2.22M8.94 15.51v2.22M10.77 16.44v1.29M12.59 17.21v.52M14.42 16.44v1.29M16.25 15.51v2.22M7.11 13.59v4.14M17.94 13.59v4.14M19.72 15.51v2.22'
			/>
			<path
				stroke='#919191'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M12.5 12.37a3.79 3.79 0 100-7.58 3.79 3.79 0 000 7.58z'
			/>
		</svg>
	);
}

export default DCVisitsSvg;
