import ApiUtils from './index';
import imageCompression from 'browser-image-compression';
import { getValueFromLocalStorage } from '../local-storage';

const baseConfig = () => {
  // use this check to validate that token is present or not
  if(!getValueFromLocalStorage("token")) {
    return window.location.pathname = "/";
  }
  return {
    headers: {
      'content-type': 'application/json',
      Authorization: `JWT ${getValueFromLocalStorage('token')}`,
    },
    baseURL: `${process.env.REACT_APP_SALES_API}/api/v1/`,
  }
};

const Api = {
  get(path, config = {}) {
    return ApiUtils.get(path, {
      ...baseConfig(),
      ...config,
    });
  },
  post(path, payload, config = {}) {
    return ApiUtils.post(path, payload, {
      ...baseConfig(),
      ...config,
    });
  },
  put(path, payload, config = {}) {
    return ApiUtils.put(path, payload, {
      ...baseConfig(),
      ...config,
    });
  },
  getImages(data) {
    const urls = Array.isArray(data) ? data : [data];
    return this.post('/image', { urls }).then((res) =>
      Array.isArray(data) ? res?.signedUrls : res?.signedUrls[0],
    );
  },
  async uploadImage(data, compress = true) {
    let file = data;
    if (compress) {
      try {
        file = await imageCompression(data, {
          maxSizeMB: 2,
        });
      } catch (err) {
        console.log(err);
      }
    }
    let formData = new FormData();
    formData.append('image', file);
    return this.post('/image/upload-image', formData).then((res) => res.url);
  },
};

export default Api;
