// BASICALLY A GLOBAL JSON OBJECT
export const initialState = {
    currentOroOffer: null
};

const reducer = (state, action) => {
     switch(action.type) {

       case 'SET_CURRENT_ORO_OFFER':
           return {
               ...state,
               currentOroOffer: action.payload
           };
       default: return state;
     }
}

export default reducer;