//DEPS
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GetUserPermissions } from '../../contexts/permission-context';

//Assets
import VisitIcon from '../../assets/visits.svg';
import LoanIcon from '../../assets/loans.svg';
import SupportLoanIcon from '../../assets/supportLoans.svg';
import AgentIcon from '../../assets/agents.svg';
import UserIcon from '../../assets/users.svg';
import CoBorrower from '../../assets/co-borrower.svg';
import ConfigIcon from '../../assets/config.svg';
import PlanIcon from '../../assets/plans.svg';
import RateIcon from '../../assets/rates.svg';
import PartnerIcon from '../../assets/partners.svg';
import goldOrnamentIcon from '../../assets/gold.svg';
import percentIcon from '../../assets/percent.svg';
import lendersIcon from '../../assets/lenders.svg';
import goldMarkingIcon from '../../assets/Gold_Marking.svg';
import { OfferContext } from '../../App';
import ClosureRequestSvg from '../svg/ClosureRequestSvg'
import ReportsSvg from '../svg/ReportSvg';

// utils
import permissions from '../../utils/permissionConfig';
import Utils from '../../utils';
import { checkIfComponentHasAccess } from '../../utils';
import DisbursementCenterSvg from '../svg/DC';
import DCVisitsSvg from '../svg/DCVisits';
import DCLoansSvg from '../svg/DCLoans';
import TakeoverSvg from '../svg/TakeoverSvg';
import BRLVisitSvg from '../svg/BRLVisitSvg';
import BRLLoansSvg from '../svg/BRLLoans';
import ELRVisitSvg from '../svg/ELRVisitSvg';
import TakeoverLoansSvg from '../svg/TakeoverLoans';
import TakeoverVisitSvg from '../svg/TakeoverVisitSvg';
import SalesVisitIcon from '../svg/SalesVisitsIcon';
import supabase from '../../utils/supabase';

const { SubMenu } = Menu;
const { Sider } = Layout;

const ADMIN_TAKEOVER_BASE_URL = 'http://localhost:3000'

const AdminSidebar = (props) => {

	const [version, setVersion] = React.useState('0.0.0');
	
	const { permissions: userPermissions } = GetUserPermissions();

	const [state, setStateHelper] = useState({
		pendingOfferCount: null,
	});

	const setState = (changes) => {
		setStateHelper((prevState) => ({
			...prevState,
			...changes,
		}));
	};

	const defaultSubMenuOpen = () => {
		let key = window.location.pathname.split('/')[1];
		return [
			'config',
		].includes(key);
	};

	const offerData = React.useContext(OfferContext);
	useEffect(() => {
		const x = offerData?.getPendingOfferCount();
	}, []);

	useEffect(() => {
		async function getVersion() {
			const { data } = await supabase.from('versions').select('*').single();
			setVersion(Utils.sumVersions(data?.admin_v1, data?.admin_v2));
		}
		getVersion();
	}, []);
	return (

		<Sider width={238} className='pt-6  bg-white border-r'>
			<div className='relative'>
			<Menu 
				mode='inline'
				selectedKeys={[
					window.location.pathname.split('/')[1], // for /loans urls
					window.location.pathname.split('/')[2]  // for /config/ urls
				  ]}
				defaultSelectedKeys={['visits']}
				defaultOpenKeys={defaultSubMenuOpen() ? ['config'] : []}
			>
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.visits.permission,
					false
				) ? (
					<Menu.Item key='visits'>
						<a href={`/visits`}>
							<div className='text-body-bold flex space-x-2'>
								<span className='mr-2 '>
									<img src={VisitIcon} alt='' />
								</span>
								<span className=''>Visits</span>
							</div>
						</a>
					</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
							userPermissions,
							permissions.visits.permission,
							false
						) ? (
							<Menu.Item key='sales-visits'>
								<a href={`/sales-visits`}>
									<div className='flex space-x-2'>
										<SalesVisitIcon/>
										<span>Sales Visits</span>
									</div>
								</a>
							</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.loans.permission,
					false
				) ? (
					<Menu.Item key='loans'>
						<a href={`/loans`}>
							<div className='flex space-x-2 text-body-bold '>
								<span className='mr-2'>
									<img src={LoanIcon} alt='' />
								</span>
								<span>Loans</span>
							</div>
						</a>
					</Menu.Item>
				) : null}

				{checkIfComponentHasAccess(
					userPermissions,
					permissions.takeover.permission,
					true
				) ? (
					<SubMenu
						key='takeover'
						title={<span className='font-bold text-base ml-2'>Takeover</span>}
						icon={<TakeoverSvg/>}
					>
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.takeover.permission,
							false
						) ? (
							<Menu.Item key='brl-visits'>
								<a href={`/takeover/brl-visits`}>
									<div className='flex space-x-2'>
										<BRLVisitSvg/>
										<span>BRL Visits</span>
									</div>
								</a>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.takeover.permission,
							false
						) ? (
							<Menu.Item key='brl-loans'>
								<a href={`/takeover/brl-loans`}>
									<div className='flex space-x-2'>
										<BRLLoansSvg/>
										<span>BRL Loans</span>
									</div>
								</a>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.takeover.permission,
							false
						) ? (
							<Menu.Item key='elr-visits'>
								<a href={`/takeover/elr-visits`}>
									<div className='flex flex-row space-x-2'>
										<ELRVisitSvg/>
										<span>ELR Visits</span>
									</div>
								</a>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.takeover.permission,
							false
						) ? (
							<Menu.Item key='takeover-visits'>
								<a href={`/takeover/takeover-visits`}>
									<div className='flex space-x-2'>
										<TakeoverVisitSvg/>
										<span>Takeover Visits</span>
									</div>
								</a>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.takeover.permission,
							false
						) ? (
							<Menu.Item key='takeover-loans'>
								<a href={`/takeover/takeover-loans`}>
									<div className='flex space-x-2'>
										<TakeoverLoansSvg/>
										<span>Takeover Loans</span>
									</div>
								</a>
							</Menu.Item>
						) : null}
					</SubMenu>
				) : null}
				<SubMenu
						key='DC'
						title={<span className='font-bold text-base ml-2'>DC</span>}
						icon={<DisbursementCenterSvg/>}
				>
							<Menu.Item key='dc-visits'>
								<a href={`/disbursement-centre/dc-visits`}>
									<div className='flex space-x-2'>
										<DCVisitsSvg/>
										<span>DC Visits</span>
									</div>
								</a>
							</Menu.Item>
							<Menu.Item key='dc-loans'>
								<a href={`/disbursement-centre/dc-loans`}>
									<div className='flex space-x-2'>
										<DCLoansSvg/>
										<span>DC Loans</span>
									</div>
								</a>
							</Menu.Item>
							<Menu.Item key='dc-details'>
								<a href={`/disbursement-centre/dc-details`}>
									<div className='flex space-x-2'>
										<DisbursementCenterSvg/>
										<span>DC Details</span>
									</div>
								</a>
							</Menu.Item>
				</SubMenu>
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.closure_friction.permission,
					false
				) ? (
					<Menu.Item key='closure-requests'>
								<a href={`/closure-requests`}>
									<div className='flex space-x-2'>
										<ClosureRequestSvg/>
										<span>Closure friction</span>
									</div>
								</a>
							</Menu.Item>
				) : null}

				{checkIfComponentHasAccess(
					userPermissions,
					permissions.support_loans.permission,
					false
				) ? (
					<Menu.Item key='support-loans'>
							    <a href={'/support-loans'}>
									<div className='flex space-x-2 text-body-bold'>
										<span className='mr-2'>
											<img src={SupportLoanIcon} alt='' />
										</span>
										<span>Support Loans</span>
									</div>
								</a>
					</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.agents.permission,
					false
				) ? (
					<Menu.Item key='agents'>
								<a href={'/agents'}>
							<div className='flex space-x-2'>
								<span className='mr-2'>
									<img src={AgentIcon} alt='' />
								</span>
								<span>Agents</span>
							</div>
						</a>
					</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.users.permission,
					false
				) ? (
					<Menu.Item key='users'>
						<a href={`/users`}>
							<div className='flex space-x-2 text-body-bold'>
								<span className='mr-2'>
									<img src={UserIcon} alt='' />
								</span>
								<span>Users</span>
							</div>
						</a>
					</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.coborrowers.permission,
					false
				) ? (
					<Menu.Item key='co-borrower'>
						<NavLink to='/co-borrower'>
							<div className='flex space-x-2 text-body-bold'>
								<span className='mr-2'>
									<img src={CoBorrower} alt='' />
								</span>
								<span>Co-borrower</span>
							</div>
						</NavLink>
					</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.config.permission,
					true
				) ? (
					<SubMenu
						key='config'
						icon={
							<span className='mr-1.5'>
								<img src={ConfigIcon} alt='' />
							</span>
						}
						title='Config'
					>
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.plans.permission,
							false
						) ? (
							<Menu.Item key='plans'>
								<NavLink to='/config/plans'>
									<div className='flex space-x-2'>
										<span className='mr-2'>
											<img src={PlanIcon} alt='' />
										</span>
										<span>Plans</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.rates.permission,
							false
						) ? (
							<Menu.Item key='rates'>
								<NavLink to='/config/rates'>
									<div className='flex space-x-2'>
										<span className='mr-2'>
											<img src={RateIcon} alt='' />
										</span>
										<span>Rates</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.partners.permission,
							false
						) ? (
							<Menu.Item key='partners'>
								<NavLink to='/config/partners'>
									<div className='flex flex-row space-x-2'>
										<span className='mr-2'>
											<img src={PartnerIcon} alt='' />
										</span>
										<span>Partners</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.gold_ornaments.permission,
							false
						) ? (
							<Menu.Item key='manage-gold-ornament-types'>
								<NavLink to='/config/manage-gold-ornament-types'>
									<div className='flex space-x-2'>
										<span className='mr-2 mt-2'>
											<img src={goldOrnamentIcon} alt='' />
										</span>
										<span>Gold Ornaments</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.pin_codes.permission,
							false
						) ? (
							<Menu.Item key='manage-pincodes'>
								<NavLink to='/config/manage-pincodes'>
									<div className='flex space-x-2'>
										<span className='mr-2'>
											<img src={VisitIcon} alt='' />
										</span>
										<span>Pin Codes</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.lenders.permission,
							false
						) ? (
							<Menu.Item key='lenders'>
								<NavLink to='/config/lenders'>
									<div className='flex space-x-2'>
										<span className='mr-2'>
											<img src={lendersIcon} alt='' />
										</span>
										<span>Lenders</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.gold_marking.permission,
							false
						) ? (
							<Menu.Item key='gold-marking-types'>
								<NavLink to='/config/gold-marking-types'>
									<div className='flex space-x-2'>
										<span className='mr-2'>
											<img src={goldMarkingIcon} alt='goldMarkingIcon' />
										</span>
										<span>Gold Marking</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.old_offers.view_offer_list.permission,
							false
						) ? (
							<Menu.Item key='old-offer'>
								<NavLink to='/config/old-offer'>
									<div className='flex space-x-2'>
										<span className='mr-2'>
											<img src={percentIcon} alt='' />
										</span>
										<span>Old Offers</span>
									</div>
								</NavLink>
							</Menu.Item>
						) : null}
						{checkIfComponentHasAccess(
							userPermissions,
							permissions.config.banners.permission,
							false
						) ? (
							<Menu.Item key='banners'>
								<a href={`/config/banners`}>
									<div className='flex space-x-2'>
										<ClosureRequestSvg/>
										<span>Banners</span>
									</div>
								</a>
							</Menu.Item>
						) : null}
					</SubMenu>
				) : null}

				{checkIfComponentHasAccess(
					userPermissions,
					permissions.reports.permission,
					false
				) ? (
					<Menu.Item key='reports'>
						<a href='/reports'>
							<div className='flex space-x-2'>
								<span className='mr-2'>
									<ReportsSvg stroke='#909090'/>
								</span>
								<span>Reports</span>
							</div>
						</a>
					</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.insights.permission,
					false
				) ? (
					<Menu.Item key='insights'>
						<NavLink to='/insights'>
							<div className='flex space-x-2'>
								<span className='mr-2'>
									<img src={VisitIcon} alt='' />
								</span>
								<span>Insights</span>
							</div>
						</NavLink>
					</Menu.Item>
				) : null}
				{checkIfComponentHasAccess(
					userPermissions,
					permissions.oro_offer.view_oro_offer.permission,
					false
				) ? (
					<Menu.Item key='oro-offer'>
						<NavLink to='/oro-offer'>
							<div className='flex space-x-2'>
								<span className='mr-2'>
									<img src={percentIcon} alt='' />
								</span>
								<span>Oro Offer</span>
								<span className='h-6 w-6 text-center text-xs p-1 rounded-lg bg-red-500 text-white'>
									{offerData?.pendingCount ?? 0}
								</span>
							</div>
						</NavLink>
					</Menu.Item>
				) : null}
			</Menu>
			</div>
			
			<p className='absolute  flex items-center justify-center  font-extrabold sidebar-left text-sm h-8 px-3 py-1 border-2 border-border-grey rounded-lg bg-white text-label bottom-4 ml-6'>
				V{version}
			</p>
		</Sider>
	);
};

export default AdminSidebar;
