import React from 'react';

function ELRVisitSvg({
	width = '24',
	height = '24',
	stroke = '#919191',
}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M5.76 12.12H1.73v8.83h4.03v-8.83zM5.76 13.33h4.21c.26 0 .5.1.69.28l1.72 1.72h2.04a1.23 1.23 0 110 2.46H9.16'
			/>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M15.82 16.77l4.48-1.69a1.46 1.46 0 011.08 2.71l-6.98 2.96c-.16.07-.34.09-.51.07l-7.96-1.08M17.36 6.2h-5.57l.96-3.15h3.65l.96 3.15zM13.82 10.93H8.26l.96-3.16h3.64l.96 3.16zM20.9 10.93h-5.57l.96-3.16h3.65l.96 3.16z'
			/>
		</svg>
	);
}

export default ELRVisitSvg;
