import React from 'react';

function DCLoansSvg({
	width = '24',
	height = '24',
	stroke = '#919191',
}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M1.14 20.89h16.34M2.22 13.79v7.1M16.4 13.79v7.1M8.77 14.41H4.29v6.48h4.48v-6.48zM14.22 14.41h-3.28v4.24h3.28v-4.24zM13.45 10.07c0 1.13-.92 2.05-2.05 2.05s-2.05-.92-2.05-2.05c0 1.13-.92 2.05-2.05 2.05s-2.05-.92-2.05-2.05c0 1.13-.92 2.05-2.05 2.05s-2.05-.92-2.05-2.05l3.29-4.54h8.62M18.86 11.11a4 4 0 100-8 4 4 0 000 8z'
			/>
			<path
				stroke='#919191'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0.75'
				d='M17.51 5.53h2.71M17.51 6.55h2.71M17.54 5.53c.97 0 1.75.45 1.75 1.01 0 .53-.69.96-1.58 1.01M17.73 7.55l1.5 2'
			/>
		</svg>
	);
}

export default DCLoansSvg;
