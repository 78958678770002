const getEventTitle = (eventName) => {
  switch (eventName) {
    // Gold loan visit events
    case 'REQUEST_VISIT':
      return 'Visit requested';
    case 'CONFIRM_VISIT':
      return 'Visit confirmed';
    case 'CANCEL_VISIT':
      return 'Visit cancelled';
    case 'START_VISIT':
      return 'Visit started';
    case 'ASSIGN_VISIT':
      return 'Agent assigned';
    case 'START_GOLD_VALUATION':
      return 'Gold valuation started';
    case 'COMPLETE_GOLD_VALUATION':
      return 'Gold valuation completed';
    case 'START_GOLD_SEALING':
      return 'Gold seal started';
    case 'COMPLETE_GOLD_SEALING':
      return 'Gold seal completed';
    case 'START_PLEDGE_CARD_PROCESS':
      return 'Pledge card process started';
    case 'COMPLETE_PLEDGE_CARD_PROCESS':
      return 'Pledge card process completed';
    case 'LEAVE_CUSTOMER':
      return 'Left customer place';
    case 'REACH_PARTNER_BRANCH_VISIT':
      return 'Reached partner branch';
    case 'COMPLETE_VISIT':
      return 'Loan visit completed';
    case 'CHANGE_PARTNER_BRANCH':
      return 'Partner branch changed';
    case 'EDIT_VISIT_DATA':
      return 'Visit data edited';
    case 'CALL_INITIATED':
      return 'Call initiated';
    case  'CALL_RECEIVED' :
      return 'Call received';

    // Release visit events
    case 'REQUEST_RELEASE':
      return 'Release requested';
    case 'CONFIRM_RELEASE':
      return 'Release confirmed';
    case 'START_RELEASE':
      return 'Release visit started';
    case 'COLLECT_GOLD_FROM_BANK':
      return 'Gold collected';
    case 'REACH_CUSTOMER_LOCATION':
      return 'Reached CX location';
    case 'UPLOAD_RELEASE_RECEIPT':
      return 'Release receipt uploaded';
    case 'COMPLETE_RELEASE':
      return 'Release visit completed';
    case 'TRIGGER_RELEASE_EMAIL_SUCCESS':
      return 'Release mail sent';
    case 'TRIGGER_RELEASE_EMAIL_FAILURE':
      return 'Release mail failed';

    // Loan events
    case 'APPROVAL_REQUESTED':
      return 'Approval requested';
    case 'APPROVE_LOAN':
      return 'Approved';
    case 'REQUEST_FUND_TRANSFER':
      return 'Fund transfer requested';
    case 'RECORD_FUND_TRANSFER':
      return 'Fund transfer completed';
    case 'COMPLETE_BLC':
      return 'BLC Completed';
    case 'COMPLETE_BLV':
      return 'BLV Completed';
    case 'REQUEST_RENEWAL':
      return 'Renewal requested';
    case 'COMPLETE_RENEWAL':
      return 'Renewal completed';
    case 'REJECT_LOAN':
      return 'Approval rejected';
    case 'INITIATE_LOAN_CLOSURE':
      return 'Closure initiated';
    case 'COMPLETE_BRANCH_RELEASE':
      return 'Branch release completed';
    case 'COMPLETE_CX_RELEASE':
      return 'CX release completed';
    case 'CLOSE_LOAN':
      return 'Loan closed';
    case 'AUCTION_LOAN':
      return 'Loan auctioned';

    // Flag Events
    case 'LOAN_RED_FLAG_STATUS_CHANGE':
      return 'Flag changed to Red';
    case 'LOAN_GREEN_FLAG_STATUS_CHANGE':
      return 'Flag changed to Green';
    case 'LOAN_QC_FAILED':
      return 'Item marked QC Failed';
    case 'LOAN_QC_PASSED':
      return 'Item marked QC Passed';
    case 'LOAN_FLAGGING_REASON_REMOVED':
      return 'Flagging reasons removed';
    case 'LOAN_FLAGGING_REASON_ADDED':
      return 'Flagging reasons added';
    case 'CLOSURE_FRICTION_FLAG_CHANGED':
			return 'Closure friction flag changed';

      // closure events
		case 'CLOSURE_REQUEST_CREATED':
			return 'Closure request created';
		case 'CLOSURE_REQUEST_CANCELLED':
			return 'Closure request cancelled';
		case 'CLOSURE_REQUEST_ADMIN_APPROVED':
			return 'Closure request admin approved';
		case 'CLOSURE_REQUEST_AUTO_APPROVED':
			return 'Closure request auto approved';


    // other events
    case 'ADD_RATE':
      return 'New rate added';
    case 'DEACTIVATE_RATE':
      return 'Rate deactivated';
    case 'REGISTER_USER':
      return 'User registered';
    case 'UPDATE_USER':
      return 'User details edited';
    case 'USER_FLAG_CHANGE_TO_GREEN':
      return 'User flag changed to green';
    case 'USER_FLAG_CHANGE_TO_RED':
      return 'User flag changed to red';
    case 'USER_FLAGGING_REASON_ADDED':
      return 'Flagging reason added';
    case 'USER_FLAGGING_REASON_REMOVED':
      return 'Flagging reason removed';
    case 'COMPLETE_PREKYC':
      return 'Pre-Kyc Completed';
    case 'COMPLETE_DATA_COLLECTION':
      return 'Data collection completed';
    case 'ADD_BANK_ACCOUNT':
      return 'Bank account added';
    case 'UPDATE_BANK_ACCOUNT':
      return 'Bank account details edited';
    case 'VERIFY_BANK_ACCOUNT':
      return 'Bank account verified';
    case 'LOAN_STATUS_CHANGE':
      return 'Loan status changed';
    case 'LOAN_ACCOUNT_CHANGE':
      return 'Loan account details edited';
    case 'LOAN_GOLD_DETAILS_CHANGE':
      return 'Gold items details edited';
    case 'LOAN_START_DATE_CHANGE':
      return 'Loan start date changed';
    case 'LOAN_GL_AMOUNT_CHANGE':
      return 'Gold loan amount changed';
    case 'EDIT_FUND_TRANSFER':
      return 'Funds transfer details edited';
    case 'LOAN_PARTNER_BRANCH_CHANGE':
      return 'Partner branch changed';
    case 'REASSIGN_VISIT':
      return 'Agent reassigned';
    case 'CREATE_AGENT':
      return 'Agent created';
    case 'UPDATE_AGENT':
      return 'Agent details edited';
    case 'DISABLE_AGENT':
      return 'Agent deactivated';
    case 'UPDATE_CHANNEL_DETAILS': 
      return 'Channel details updated';

    // renewal events
    case 'RENEWAL_GENERATE_OTP':
      return 'Renewal OTP generated';
    case 'RENEWAL_VERIFY_OTP':
      return 'Renewal OTP verified';
    case 'RECORD_RENEWAL_FUND_TRANSFER':
      return 'Excess Renewal Amount Transferred';
    case 'LOAN_PRE_CLOSURE_ENABLED':
      return 'Pre-closure charges are enabled';
    case 'LOAN_PRE_CLOSURE_DISABLED':
      return 'Pre-closure charges are disabled';
    case 'CHANGE_BANK_ACCOUNT': 
      return 'Bank Account Changed'; 
  }
};

export const getSubEventTitle = (eventName, bankAccountNo, karzaErr) => {
  switch(eventName) {
    case 'EDIT':
      return `ACC NO: ${bankAccountNo} account details edited`;
    case 'ADD':
      return `New Account added ACC NO: ${bankAccountNo}`;
    case 'MANUALLY_VERIFIED':
      return `ACC NO: ${bankAccountNo} verification marked MANUALLY VERIFIED`;
    case 'VERIFICATION_STARTED':
      return `ACC NO: ${bankAccountNo} verification started`; 
    case 'VERIFICATION_FAILED':
      return `ACC NO: ${bankAccountNo} verification failed - Error : ${karzaErr}`;  
    case 'VERIFICATION_SUCCESS':
      return `ACC NO: ${bankAccountNo} verification successful`;  
  }
}

export default getEventTitle;
