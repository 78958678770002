import React from 'react';

export default function SalesVisitSvg({
	width = '24',
	height = '24',
	stroke = '#909090',
	className = '',
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 25 24'
			fill='none'
			className={className}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M21.3574 10C21.3574 17 12.3574 23 12.3574 23C12.3574 23 3.35742 17 3.35742 10C3.35742 7.61305 4.30563 5.32387 5.99346 3.63604C7.68129 1.94821 9.97047 1 12.3574 1C14.7444 1 17.0336 1.94821 18.7214 3.63604C20.4092 5.32387 21.3574 7.61305 21.3574 10Z'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.8008 16.1493C11.0134 16.1493 10.3346 16.0226 9.76443 15.7691C9.2033 15.5067 8.68742 15.113 8.2168 14.5881L9.60152 13.2169C9.90924 13.5699 10.2486 13.8459 10.6197 14.045C10.9998 14.2351 11.4388 14.3301 11.9366 14.3301C12.3891 14.3301 12.7375 14.2487 12.9819 14.0858C13.2353 13.9228 13.362 13.6966 13.362 13.407C13.362 13.1445 13.2806 12.9318 13.1176 12.7689C12.9547 12.606 12.7375 12.4657 12.466 12.3481C12.2035 12.2304 11.9094 12.1173 11.5836 12.0087C11.2668 11.9001 10.95 11.7779 10.6333 11.6421C10.3165 11.4973 10.0224 11.3208 9.75086 11.1127C9.48839 10.8954 9.27571 10.6285 9.1128 10.3117C8.94989 9.98587 8.86843 9.5786 8.86843 9.08987C8.86843 8.50159 9.00872 7.99476 9.28928 7.56939C9.5789 7.14401 9.97712 6.82272 10.4839 6.60551C10.9908 6.37924 11.5745 6.26611 12.2352 6.26611C12.905 6.26611 13.5159 6.38377 14.0679 6.61908C14.6291 6.8544 15.0907 7.17569 15.4527 7.58296L14.0679 8.95411C13.7783 8.65545 13.4842 8.43371 13.1855 8.2889C12.8959 8.14409 12.5656 8.07169 12.1945 8.07169C11.8234 8.07169 11.5293 8.14409 11.3121 8.2889C11.0949 8.42466 10.9863 8.62377 10.9863 8.88623C10.9863 9.1306 11.0677 9.32971 11.2306 9.48357C11.3935 9.62838 11.6062 9.75508 11.8687 9.86369C12.1402 9.9723 12.4343 10.0809 12.7511 10.1895C13.0769 10.2981 13.3982 10.4248 13.715 10.5696C14.0317 10.7144 14.3214 10.9 14.5838 11.1262C14.8553 11.3434 15.0726 11.624 15.2355 11.9679C15.3984 12.3028 15.4798 12.7191 15.4798 13.2169C15.4798 14.131 15.154 14.8505 14.5024 15.3754C13.8507 15.8913 12.9502 16.1493 11.8008 16.1493Z'
				fill='#919191'
			/>
		</svg>
	);
}
