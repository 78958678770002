import React from 'react';




function ClosureRequestSvg({
	width = '22',
	height = '22',
	stroke = '#909090',
	className,
}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 22 22'
			className={className}
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.68'
				d='M15.96 3.86H4.2a1.68 1.68 0 00-1.68 1.68V17.3c0 .927.752 1.68 1.68 1.68h11.76a1.68 1.68 0 001.68-1.68V5.54a1.68 1.68 0 00-1.68-1.68zM7.56 8.9l5.04 5.04M12.6 8.9l-5.04 5.04'
			/>
			<path
				fill='#919191'
				d='M21.42.5A.42.42 0 0021 .08h-3.78a.42.42 0 000 .84h3.36v3.36a.42.42 0 00.84 0V.5zm-4.323 4.496l4.2-4.2-.594-.594-4.2 4.2.594.594z'
			/>
		</svg>
	);
}

export default ClosureRequestSvg;
