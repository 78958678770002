import { checkIfComponentHasAccess } from '../utils';
import { getValueFromLocalStorage } from '../utils/local-storage';

export default function AccessCheck({
	children,
	requiredPermissions = [],
	alternateChildren,
	looseCheck,
}) {
	const userPermissions = getValueFromLocalStorage('userPermissions');

	const allowed = checkIfComponentHasAccess(
		userPermissions,
		requiredPermissions,
		looseCheck
	);

	return allowed ? children : alternateChildren;
}
