import "antd/dist/antd.less";
import React from "react";
import ReactDOM from "react-dom";
import { Empty } from "antd";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/app.css";
import "./styles/tailwind.css";
import { DataLayer } from './DataLayer';
import reducer, { initialState } from './reducer';

const currEnv = process.env.REACT_APP_ENV?.toLowerCase();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACKING_RATE) || 0,
  debug: currEnv === 'uat',
  environment: currEnv,
  autoSessionTracking: true,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<Empty className="pt-48" description="Something went wrong" />}>
    <DataLayer initialState={initialState} reducer={reducer}>
      <App />
    </DataLayer>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
