import React from 'react';

function TakeoverSvg({
	width = '24',
	height = '24',
	stroke = '#919191',
}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M22.22 12.85v6.02c0 .42-.34.76-.76.76h-6.23a.76.76 0 01-.76-.76v-7.75c0-.42.34-.76.76-.76h4.49l2.5 2.49z'
			/>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M19.72 10.36v2.22c0 .21.17.38.38.38h2.12M16.09 14.58h4.51M16.09 16.2h4.51M16.09 17.83h4.51M16 6.95l1.17 1.17 1.18-1.17'
			/>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M12.08 4.37h4.16c.52 0 .94.42.94.94v2.82M10.82 19.63l1.17-1.17-1.17-1.17'
			/>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M11.99 18.46H6.71c-.52 0-.94-.42-.94-.94V16.2M9.53 4.37H1.78v9.27h7.75V4.37z'
			/>
		</svg>
	);
}

export default TakeoverSvg;
