import moment from 'moment';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { notification } from 'antd';
// import jwt_decode from 'jwt-decode';
import getEventTitle from './event-title';
import { getSubEventTitle } from './event-title';
import { getValueFromLocalStorage } from './local-storage';
import permissions from './permissionConfig';
import Api from './ApiUtils/Api';
import { OPERATIONAL_CITIES } from './constants';
import federal from '../assets/federal_logo.svg';
import fincare from '../assets/fincare_logo.svg';
import tpf from '../assets/tpf_logo.png';
import sfl from '../assets/sfl_logo.png';
import kumbhat from '../assets/kf-logo.png';
import { getValueFromCookie } from './cookies';

export const getToken = () => {
	if (getValueFromLocalStorage('token')) {
		return true;
	}
	return false;
};

export const formatLog = (log) => {
	const { event, created_by, created_at, type='', createdBy } = log;
	const { first_name, last_name, user_type, branch_name } = created_by ?? {};

	if(event === 'VERIFY_BANK_ACCOUNT' || event === 'UPDATE_BANK_ACCOUNT' || event === 'ADD_BANK_ACCOUNT') {
		return {
			title: getSubEventTitle(log?.data?.sub_event, log?.data?.bank_acc_no, log?.data?.error_message),
			datetime: created_at,
			userType: user_type,
			creatorName:
					event === 'CLOSURE_REQUEST_AUTO_APPROVED' 
					? 'AUTO APPROVED'
					: user_type === 'BANK'
					? branch_name
					: first_name === last_name
					? first_name
					: first_name + ' ' + (last_name ?? ''),
			type
		}
	}
	return {
		title: getEventTitle(event),
		datetime: created_at,
		userType: user_type,
		creatorName:
				event === 'CLOSURE_REQUEST_AUTO_APPROVED' 
				? 'AUTO APPROVED'
				: user_type === 'BANK'
				? branch_name : createdBy?.name
                ? createdBy?.name
				: first_name === last_name
				? first_name
						: first_name + ' ' + (last_name ?? ''),
		type
	};
};


export const getUserAttributes = (userType) => {
	switch (userType) {
		case 'SUPER_ADMIN':
			return ['L', 'oro-blue'];
		case 'FIELD_AGENT':
			return ['A', 'oro-purple'];
		case 'BANK':
			return ['B', 'oro-pink'];
		case 'CUSTOMER':
			return ['C', 'oro-yellow'];
		default:
			return ['U', 'oro-yellow'];
	}
};

const delay = (t = 3000) => new Promise((res, rej) => setTimeout(res, t));

const toTitleCase = (phrase) =>
	phrase
		?.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

export const genColumnKey = (key, dataIndex, index) => {
	if (key) {
		return key;
	}
	if (!key && dataIndex) {
		if (Array.isArray(dataIndex)) {
			return dataIndex.join('-');
		}
		return dataIndex;
	}
	return `${index}`;
};
export const avatarMap = (name, maxInitials) => {
	return name
		.split(/\s/)
		.map(function (part) {
			return part.substring(0, 1).toUpperCase();
		})
		.filter(function (v) {
			return !!v;
		})
		.slice(0, maxInitials)
		.join('');
};

export const exportToXLS = (fileName, headers, exportdata) => {
	let exportdataArr = exportdata.map((data) => {
		return Object.keys(data).map((datamap) => {
			return data[datamap];
		});
	});
	var worksheet = XLSX.utils.aoa_to_sheet([headers, ...exportdataArr]);

	var new_workbook = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS');
	XLSX.writeFile(
		new_workbook,
		`${fileName}-${moment(new Date()).format('DD/MM/YYYY')}.xlsx`
	);

	return;
	
};

export const copyToClipboard = async (copyValue) => {
	try {
		await navigator?.clipboard?.writeText(copyValue);
		return notification.success({
			message: 'Copied value, paste it anywhere!',
		});
	} catch (error) {
		notification.error({
			message: 'Cannot copy text as your browser does not allow it!',
		});
	}
};

const Number = (props) => {
	return props.number.toLocaleString('en-IN', { maximumFractionDigits: 2 });
};

const Currency = (props) =>
	props.number.toLocaleString('en-IN', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

export const getPercentage = (a, b, n) =>
	parseFloat(((a / b) * 100).toFixed(n));

const get_image_url = async (props) => {
	const GET_VALID_URL = {
		query: `
      mutation getValidURL ($url:[String!]) {
        getValidUrlsArray(image_urls: $url) {
          urls
          message
        }
      }
    `,
	};

	if (props?.url?.length) {
		GET_VALID_URL.variables = {
			url: props?.url?.map(
				(url) => `${url?.split(process.env.REACT_APP_S3_BASE_URL)[1]}`			),
		};
	} else {
		return;
	}

	const valid_url = await axios({
		method: 'post',
		url: process.env.REACT_APP_GQL_ENDPOINT,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getValueFromLocalStorage('token')}`,
		},
		data: GET_VALID_URL,
	})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			// console.log("err: ", err);
		});

	if (valid_url === undefined) {
	} else {
		if (valid_url.data.errors)
			console.log('Error', valid_url.data.errors, props.url[0]);
		else if (valid_url.data.data.getValidUrlsArray.urls.length === 1)
			return valid_url.data.data.getValidUrlsArray.urls[0];
		else return valid_url.data.data.getValidUrlsArray.urls;
	}
};

const getLoanIdFromNumber = (loanNumber, city) => {
	let id = loanNumber?.replace('OMGL', '');
	if (loanNumber?.includes('-')) {
		return parseInt(loanNumber?.replace('OMGL', ''));
	} else {
		return parseInt(`${parseInt(id) - parseInt(city) * 10000000}`.slice(0, -1));
	}
};

const fetchPermittedRoute = (validPermissions) => {
	const userPermissions = new Set(validPermissions);
	const routesPermissionMap = {
		'/visits': permissions.visits.permission,
		'/loans': permissions.loans.permission,
		'/support-loans': permissions.support_loans.permission,
		'/agents': permissions.agents.permission,
		'/users': permissions.users.permission,
		'/config/plans': permissions.config.plans.permission,
		'/config/rates': permissions.config.rates.permission,
		'/config/partners': permissions.config.partners.permission,
		'/config/manage-gold-ornament-types': permissions.config.gold_ornaments.permission,
		'/config/manage-pincodes': permissions.config.pin_codes.permission,
		'/config/lenders': permissions.config.lenders.permission,
		'/config/gold-marking-types': permissions.config.gold_marking.permission,
		'/config/old-offer': permissions.config.old_offers.view_offer_list.permission,
		'/insights': permissions.insights.permission,
		'/oro-offer': permissions.oro_offer.permission,
	};
	for (let key in routesPermissionMap) {
		if (userPermissions.has(routesPermissionMap[key][0])) return key;
	}
	return '/';
};

const intersect = (a = [], b = []) => {
	const filtered = a?.filter(Set.prototype.has, new Set(b));
	return Array.from(new Set(filtered));
};

// this check returns true if component is allowed to current user permissions otherwise false
const checkIfComponentHasAccess = (
	userPermissions = [],
	componentPermissions = [],
	looseCheck = false
) => {
	// allow component rendering if it requires no permissions
	if (componentPermissions.length === 0) {
		return true;
	}

	const commonPermissions = intersect(
		userPermissions,
		componentPermissions
	)?.length;

	const isComponentAccessAllowed = looseCheck
		? commonPermissions > 0 // if any permission matches
		: commonPermissions === componentPermissions.length; // if all permissions are given to user

	return isComponentAccessAllowed;
};

const filterUniqueItems = array => array.filter((val, index, arr) => arr?.indexOf(val) === index);

function getVisitNumberFromDisplayID(visitDisplayNumber) {
	const prefixList = ['OMBV', 'OMTV', 'OMV', 'OMEV'];
	let result = visitDisplayNumber;
	for (let prefix of prefixList) {
	  if (visitDisplayNumber.startsWith(prefix)) {
		result = visitDisplayNumber.slice(prefix.length);
		break;
	  }
	}
	const num = parseInt(result);
	return isNaN(num) ? 0 : num;
  }

const getGeographyFromPincode = async (pincode) => {
	try {
		const { data = {}, status } = await Api.get(`/website/${pincode}`, {
			headers: {
				'X-Renewal-Secret': process.env.REACT_APP_REGISTER_SECRET,
				Authorization: `JWT ${getValueFromLocalStorage('token')}`,
			},
		});
  if(status === 200) return data;
	} catch (error) {
		notification.error({
			message: 'Error!',
			description: error?.response?.data?.message,
		});
		throw error;
	}
};  

const CityIdMapper = (city) => {
	if (city?.toLowerCase() === OPERATIONAL_CITIES.CHENNAI?.toLowerCase()) {
		return process.env.REACT_APP_CHENNAI_CITY_ID;
	}
	if (city?.toLowerCase() === OPERATIONAL_CITIES.BENGALURU?.toLowerCase()) {
		return process.env.REACT_APP_BENGALURU_CITY_ID;
	}
	if (city?.toLowerCase() === OPERATIONAL_CITIES.HYDERABAD?.toLowerCase()) {
		return process.env.REACT_APP_HYDERABAD_CITY_ID;
	}
	if (city?.toLowerCase() === OPERATIONAL_CITIES.PUNE?.toLowerCase()) {
		return process.env.REACT_APP_PUNE_CITY_ID;
	}
	if (city?.toLowerCase() === OPERATIONAL_CITIES.VIJAYAWADA?.toLowerCase()) {
		return process.env.REACT_APP_VIJAYAWADA_CITY_ID;
	}
	if (city?.toLowerCase() === OPERATIONAL_CITIES.KOCHI?.toLowerCase()) {
		return process.env.REACT_APP_KOCHI_CITY_ID;
	}
	return '';
}

const fetchPartnerLogo = (partnerLogo) => {
	switch(partnerLogo){
		case 'FEDERAL':
			return federal
		case 'TPF':
			return tpf;
		case 'SFL':
			return sfl;
		case 'FINCARE':	
			return fincare;
		default:
			return kumbhat;
				
	}
}

export {
	Number,
	Currency,
	CityIdMapper,
	get_image_url,
	getLoanIdFromNumber,
	checkIfComponentHasAccess,
	fetchPermittedRoute,
	delay,
	toTitleCase,
	filterUniqueItems,
	getVisitNumberFromDisplayID,
	getGeographyFromPincode,
	fetchPartnerLogo
};


class Utils {
	static moneySeperator(num) {
		if (typeof num === 'string') {
			return parseFloat(parseFloat(num).toFixed(2))?.toLocaleString('en-IN');
		} else if (typeof num === 'number') {
			return parseFloat(num.toFixed(2))?.toLocaleString('en-IN');
		}
	}
	static trimMobileNumberPrefix = (mobileNumber) => {
		if (!mobileNumber) return null;
		return mobileNumber.replace(/^\+?91|\D/g, '');
	};
	
	static maskMobileNumber(phoneNumber) {
		const countryCode = "+91";
		const lastDigits = phoneNumber?.slice(-4);
		const maskedPart = "X"?.repeat(phoneNumber?.length - countryCode?.length - lastDigits?.length);
	  
		return countryCode + maskedPart + lastDigits;
	  }

	static currentUser() {
		return {
			id: getValueFromCookie("user").id,
			first_name: getValueFromCookie("user").name,
			user_type: 'SUPER_ADMIN',
		};
	}

	static capitalizeFirstLetter(string) {
		return string
			?.toLowerCase()
			?.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	static sumVersions(version1, version2) {
		return version1
			?.split('.')
			?.map((num, index) =>
				(
					parseInt(num, 10) +
					parseInt(version2.split?.('.')?.[index] || '0', 10)
				)?.toString()
			)
			?.join('.');
	}

	static debounce(fn, delay) {
		const timer = setTimeout(fn, delay);
		return timer;
	}

	static generateHyperlink(description, hyperlinkData, linkData) {
		let newDescription = description;

		const VisitsLinksEnum = {
			FRESH_LOAN_VISIT: `${process.env.REACT_APP_ADMIN_URL}/visits/view`,
			TAKEOVER_BRL_VISIT: `${process.env.REACT_APP_ADMIN_URL}/takeover/brl-visits`,
			ORO_OFFER: `${process.env.REACT_APP_ADMIN_URL}/oro-offer/view`,
		};

		switch (linkData) {
			case 'SALES_VISIT': {
				const _ = (hyperlinkData || [])?.forEach((item) => {
					newDescription = newDescription?.replace(
						item.key,
						`<a target="_blank" class="underline leading-3 inline" href=${`${
							VisitsLinksEnum[item.entity]
						}/${item.value}`}>${item.text}</a>`
					);
				});

				return newDescription;
			}
			default: {
				for (const hyperlink of hyperlinkData) {
					const { value, text, key } = hyperlink;
					newDescription = description.replaceAll(
						key,
						`<a target="_blank" class="underline leading-3 inline" href=${`${value}`}>${text}</a>`
					);
				}
				return newDescription;
			}
		}
	}
	

}


export default Utils ;
