import React from 'react';

function BRLLoansSvg({
	width = '24',
	height = '24',
	stroke = '#919191',
}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M5.76 10.03H1.73v8.83h4.03v-8.83zM5.76 11.24h4.21c.26 0 .5.1.69.28l1.72 1.72h2.04a1.23 1.23 0 110 2.46H9.16'
			/>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M15.82 14.68l4.48-1.69a1.46 1.46 0 011.08 2.71l-6.98 2.96c-.16.07-.34.09-.51.07l-7.96-1.08M7.58 11.2V5.14h13.18v7.33M10.2 5.23c-.05 1.4-1.2 2.53-2.61 2.53M20.76 7.76c-1.39 0-2.53-1.08-2.62-2.44M18.13 13.49a2.62 2.62 0 012.62-2.58'
			/>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='0.75'
				d='M12.56 6.94h3.22M12.56 8.15h3.22M12.59 6.94c1.15 0 2.09.54 2.09 1.21 0 .63-.82 1.14-1.88 1.2M12.81 9.35l1.79 2.38'
			/>
		</svg>
	);
}

export default BRLLoansSvg;
